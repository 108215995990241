import { sendProviderMail } from '../../util/api';
import { storableError } from '../../util/errors';



export const ACCEPT_INVITATION_REQUEST = 'app/acceptInvitation/ACCEPT_INVITATION_REQUEST';
export const ACCEPT_INVITATION_SUCCESS = 'app/acceptInvitation/ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_ERROR = 'app/acceptInvitation/ACCEPT_INVITATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  invitationAceept: [],
  invitationAceeptError: null,
  invitationAceeptInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ACCEPT_INVITATION_REQUEST:
      return {
        ...state,
        invitationAceeptInProgress: true,
        invitationAceeptError: null,
      };
    case ACCEPT_INVITATION_SUCCESS:
      return { 
        ...state, 
        invitationAceeptInProgress: false, 
        invitationAceept: payload 
      };
    case ACCEPT_INVITATION_ERROR:
      return { 
        ...state, 
        invitationAceeptInProgress: false, 
        invitationAceeptError: payload
       };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const invitationAceeptInProgress = state => {
  return state?.acceptInvitation?.invitationAceeptInProgress;
};

// ================ Action creators ================ //

export const acceptRequest = () => ({ type: ACCEPT_INVITATION_REQUEST });
export const acceptSuccess = (response) => ({ 
  type: ACCEPT_INVITATION_SUCCESS,
  payload: response, 
});
export const acceptError = error => ({
  type: ACCEPT_INVITATION_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //


export const loadData = (params, search) =>(dispatch)=> {
  const listingId = params.id;
  
  if (!listingId) {
    return Promise.reject(new Error('Accept Invitation already in progress'));
  }
  dispatch(acceptRequest());

  // Note that the thunk does not reject when the verification fails, it
  // just dispatches the login error action.
  return sendProviderMail({listingId:listingId})
    .then(res => {
      const response = res?.data?.data
      dispatch(acceptSuccess(response))
    })
    .catch(e => dispatch(acceptError(storableError(e))));
};
