import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { fetchCustomers } from '../../util/api';


// ================ Action types ================ //
export const SET_INITIAL_STATE = 'app/ShowCustomersPage/SET_INITIAL_STATE';
export const SHOW_CUSTOMERS_REQUEST = 'app/ShowCustomersPage/SHOW_CUSTOMERS_REQUEST';
export const SHOW_CUSTOMERS_SUCCESS = 'app/ShowCustomersPage/SHOW_CUSTOMERS_SUCCESS';
export const SHOW_CUSTOMERS_ERROR = 'app/ShowCustomersPage/SHOW_CUSTOMERS_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/ShowCustomersPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ShowCustomersPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ShowCustomersPage/FETCH_LISTINGS_ERROR';


// ================ Reducer ================ //
const initialState = {
  fetchCustomersInProgress: false,
  fetchCustomersError: null,
  fetchListingsInProgress: false,
  fetchListingsError: null,
};

export default function ShowCustomersPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_CUSTOMERS_REQUEST:
      return { ...state, fetchCustomersInProgress: true, fetchCustomersError: null };
    case SHOW_CUSTOMERS_SUCCESS:
      return { ...state, fetchCustomersInProgress: false, fetchCustomers: payload };
    case SHOW_CUSTOMERS_ERROR:
      return { ...state, fetchCustomersInProgress: false, fetchCustomersError: payload };
    /////////////////////
    case FETCH_LISTINGS_REQUEST:
      return { ...state, fetchListingsInProgress: true, fetchListingsError: null };
    case FETCH_LISTINGS_SUCCESS:
      return { ...state, fetchListingsInProgress: false, fetchListings: payload };
    case FETCH_LISTINGS_ERROR:
      return { ...state, fetchListingsInProgress: false, fetchListingsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //
export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showCustomersRequest = () => ({
  type: SHOW_CUSTOMERS_REQUEST,
});

export const showCustomersSuccess = (response) => ({
  type: SHOW_CUSTOMERS_SUCCESS,
  payload: response
});

export const showCustomersError = e => ({
  type: SHOW_CUSTOMERS_ERROR,
  error: true,
  payload: e,
});

/////////////////////
export const fetchListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});

export const fetchListingsSuccess = (response) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response
});

export const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});


// ================ Thunks ================ // 

export const showCustomers = userId => (dispatch, getState, sdk) => {
  dispatch(showCustomersRequest());

  fetchCustomers({ photoGrapherId: userId })
    .then((response) => {
      const customers = response?.data;

      dispatch(fetchListingsRequest());

      sdk.ownListings.query({})
        .then(res => {
          const allListings = res?.data?.data;

          const customersWithListings = customers.reduce((obj, c) => {
            const filteredListings = allListings.filter((listing) => {
              const { customer } = listing?.attributes?.publicData;
              return c?.id?.uuid === customer
            });

            obj[c?.id?.uuid] = filteredListings;
            return obj;
          }, {})

          dispatch(fetchListingsSuccess(customersWithListings));
        })
        .catch(e => dispatch(fetchListingsError(storableError(e))));

      dispatch(showCustomersSuccess(customers));
      return response;
    })
    .catch(e => dispatch(showCustomersError(storableError(e))));
};



export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
  await dispatch(fetchCurrentUser())

  const currentUser = getState().user.currentUser;
  const userId = currentUser?.id?.uuid;
  dispatch(setInitialState());

  return Promise.all([
    dispatch(showCustomers(userId))
  ]);
};